body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    background-color: #FAFAFA;
    font: 12pt "Tahoma";
}

.detail1 {
    padding-top: 10px;
    line-height: 17px;
    text-align: center;
    width: 73%;
}

.detail {
    padding-top: 10px;
    line-height: 17px;
    text-align: center;
    width: 100%;
}

.button1 {
    background-color: #36B9F6;
    border: 2px solid transparent;
    border-radius: 8px;
    color: white;
    padding: 14px 25px;
    line-height: 20px;
    margin-left: 70%;
    text-align: center;
    text-decoration: none;
    display: flex;
    justify-content: flex-end;
}

.notfound {
    text-align: center;
    color: black;
    font-family: Tahoma;
    margin-top: 20%;
    font-size: 30px;
}



.button2 {
    background-color: #008CBA;
    border: 2px solid transparent;
    border-radius: 8px;
    color: white;
    padding: 14px 25px;
    line-height: 20px;
    margin-left: 2%;
    text-align: center;
    text-decoration: none;
    display: inline-block;
}

.button1:active {
    background-color: deeppink;
}

.button1:hover {
    background-color: #008CBA;
    color: white;
}


.font1 {
    font-size: 24px;
}

.font2 {
    font-size: 21px;
}

.font3 {
    font-size: 21px;
}

.image {
    padding: 6px;
    padding-left: 15px;
    text-align: left;
}

img[src=""] {
    display: block;
}

.head {
    display: flex;
    flex-direction: row;
}

.leftali {
    text-align: left;
    font-size: 20px;
    padding-top: 5px;
    padding-left: 5px;
    width: 50%;
}

.rightali {
    text-align: end;
    font-size: 20px;
    /*padding: 5px;*/
    padding-top: 5px;
    padding-right: 5px;
    width: 50%;
}

.leftalit {
    text-align: left;
    font-size: 20px;
    padding-top: 5px;
    padding-left: 5px;
    width: 50%;
}

.rightalit {
    text-align: end;
    font-size: 26px;
    /*padding: 5px;*/
    padding-top: 5px;
    padding-right: 5px;
    width: 50%;
}

.centerali1 {
    text-align: center;
    font-size: 20px;
    padding-top: 5px;
    width: 30%;
}

.width {
    width: 90%;
    display: flex;
    flex-direction: row;
}

.Row1 {
    display: flex;
    flex-direction: row
}

.Row2 {
    width: 50%;
    display: flex;
    flex-direction: row
}

.section {
    border-top: 1px solid black !important;
    border-bottom: 2px;
    width: 100% !important;
    padding-top: 5px;
    line-height: 16px;
}

.bor {
    border: 1px solid black !important;
    width: 100% !important;
    height: 13.5%;
}

.rowx::after {
    content: "";
    display: table;
    clear: both;
}

table th {
    padding-top: 01px;
    padding-bottom: 1px;
    text-align: center;
}

td:empty::after {
    content: "\00a0";
}


table td {
    padding-top: 01px;
    padding-bottom: 1px;
    font-size: 20px;
    font-weight: bold;
}

.table-bordered {
    width: 100%;
    border-left: 1px solid black !important;
    border-right: 1px solid black !important;
    border-bottom: 1px solid black !important;
    border-top: 1px solid black !important;
}

    .table-bordered td {
        border-right: none !important;
        border-bottom: none !important;
        border-top: none !important;
        border-left: 1px solid black !important;
    }

    .table-bordered th {
        border-left: 0px solid black !important;
        border-right: 1px solid black !important;
        border-bottom: 1px solid black !important;
        border-top: 1px solid black !important;
    }

table thead {
    font-size: 20px;
    border-left: 1px solid black;
    border-right: 1px solid black;
}

.gst1 {
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    font-size: 18px;
}

.col14 {
    font-size: 18px;
    width: 33%;
    height: 20px;
    padding-left: 85px;
}

.col15 {
    padding-left: 5px;
    font-size: 18px;
    /*line-height: 18px;*/
    width: 29%;
    height: 20px;
}

.col14s {
    padding-left: 3px;
}

.col15s {
    padding-left: 3px;
}

.space1 {
    padding-top: 8px;
}

.padding1 {
    text-align: left;
    padding-right: 10px;
    padding-left: 50px;
}

.col11 {
    width: 65%;
    height: 20px;
    font-size: 20px;
    padding-left: 85px;
    padding-top: 7px;
}

.col12 {
    width: 25%;
    height: 20px;
    font-size: 26px;
    padding-top: 10px;
}

.col13 {
    height: 20px;
    font-size: 26px;
    padding-top: 10px;
    text-align: end;
}

.row11 {
    display: flex;
    line-height: 11px;
}
.row11m {
    display: flex;
    line-height: 11px;
    padding-top:5px;
}

.discnt {
    font-size: 20px;
    /* height: 60%;*/
    /*border: 1px solid yellow;*/
    display: flex;
}

.net-amnt {
    /*height: 20px;*/
    /*display: inline-block;*/
    /*width: 90%;*/
    /*text-align: right;*/
    font-size: 26px;
    /*padding-left:116px;*/
    /*text-align: right;*/
    /* display:flex;
    justify-content:flex-end;*/
    float: right;
    /*align-items:end;*/
    /*text-align:end;*/
}


.net-tot {
    padding-top: 30px;
    display: flex;
    flex-direction: row;
}


.sub-heading11 {
    /*padding-top: 3px;*/
    padding-left: 30px;
    font-size: 20px;
}
.sub-heading11-ind {
    border:1px solid black;
    /*padding-left: 50px;*/
    font-size: 20px;
}

.sub-heading12 {
    padding-top: 14px;
    padding-left: 85px;
    font-size: 20px;
}




.rightt {
    text-align: right;
    padding: 5px;
}

.leftt {
    text-align: left;
    padding: 5px;
}

.center {
    text-align: center;
}

.w-1 {
    width: 7%;
}

.w-2 {
    width: 43%;
}

.w-3 {
    width: 13%;
}

.w-4 {
    width: 42%;
}

.headbor {
    border: 2px solid black;
    height: 450px;
}

.page {
    width: 210mm;
    min-height: 150mm;
    /* min-height: 297mm;*/
    padding: 10mm;
    margin: 5mm auto;
    border: 1px #D3D3D3 solid;
    border-radius: 5px;
    background: white;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}
.txt-right {
float:right;
padding-right:10px;
/*clear:both;*/
}
.txt-right1 {
    float: right;
    padding-right: 15px;
    /*clear:both;*/
}

.subpage {
    /*padding: 0.5cm;*/
    /* height: 297mm;*/
    height: 165mm;
    border: 2px solid Black;
    /*outline: 2cm #FFEAEA solid;*/
}

.TableWid {
    width: 100%;
    height: 36%;
}

.col1 {
    width: 44%;
    /*height:20%;*/
    /*border:1px solid red;*/
}
.col1-m {
    width: 30%;
    padding-left:7px;
    /*height:20%;*/
    /*border:1px solid yellow;*/
}
.row-km {
    display: flex;
    padding-left:12px;
    /*padding-top: 6px;*/
}
.col1-o {
    width: 28%;
    padding-left: 4px;
    /*height:20%;*/
    /*border: 1px solid blue;*/
}
.wid-openkm {
    width: 48%;
    /*border: 1px solid black;*/
    /*font-size: 20px;*/
}
.wid-closekm {
    width: 44%;
    /*border: 1px solid black;*/
}

.txt-rightopen {
    /*border: 1px solid black;*/
    /*float: right;*/
    width: 35%;
    /*padding-left: 3px;*/
    /*font-size: 20px;*/
    /*clear:both;*/
}
.wid-col1 {
    /*border: 1px solid black;*/
    width: 7%;
    /*font-size: 20px;*/
}


.wid-sgst1 {
    width: 28%;
    /*border:1px solid black;*/
}

.wid-sgst2 {
    width: 24%;
    /*border: 1px solid black;*/
}

.wid-per {
    /*border: 1px solid black;*/
    width: 10%;
}

.wid-col {
    /*border: 1px solid black;*/
    width: 7%;
}

.wid-sgstval {
    /*border: 1px solid black;*/
    width: 27%;
}





.txt-rightclose {
    border: 1px solid black;
    float: right;
    width: 37%;
    padding-right: 15px;
    font-size: 20px;
    /*clear:both;*/
}
.col-net {
    width: 10%;
}

.width1 {
    width: 50%;
}
.col{
    /*border:1px solid black;*/
}

.Col {
    display: flex;
    flex-direction: column;
}

.Column {
    /*border:1px solid black;*/
    text-align: left;
    padding-left: 10px;
    font-size: 20px;
    width: 20%;
}

.Column-bt {
    /*border:1px solid black;*/
    text-align: left;
    padding-left: 8px;
    font-size: 20px;
    width: 20%;
}

.Column-c {
    /*border: 1px solid black;*/
    text-align: left;
    padding-left: 10px;
    font-size: 20px;
    width: 20%;
}

.Column-g {
    /*border: 1px solid black;*/
    text-align: left;
    padding-left: 10px;
    font-size: 20px;
    width: 20%;
}

.Column-v {
    /*border: 1px solid black;*/
    text-align: left;
    padding-left: 9px;
    font-size: 20px;
    width: 22%;
}

.Column-col {
    /*border: 1px solid black;*/
    text-align: left;
    font-size: 20px;
    padding-left: 4px;
    width: 1%;
}

.Column1 {
    text-align: left;
    font-size: 20px;
    padding-left: 14px;
    width: 3%;
}

.Column-b {
    /*border: 1px solid black;*/
    text-align: left;
    font-size: 20px;
    padding-left: 13px;
    width: 3%;
}

.Column2 {
    /*border: 1px solid red;*/
    text-align: left;
    font-size: 20px;
    padding-left: 10px;
    width: 50%;
}
.col {
    /*border: 1px solid black;*/
    /*height: 50%;*/
    /*background-color: #112432;*/
    /*width:10%;*/
}

.Column-vn {
    /*border: 1px solid red;*/
    text-align: left;
    font-size: 20px;
    padding-left: 11px;
    width: 51%;
}

.Column3 {
    /*border:1px solid black;*/
    text-align: left;
    font-size: 20px;
    padding-left: 10px;
    width: 50%;
}

.row-gst {
    display: flex;
    padding-top: 6px;
}

.row-cgst {
    display: flex;
    flex-direction: row;
    border: 1px solid red;
}

.row-dis {
    height: 50%;
}


.page {
    font-weight: bold;
    font-family: "Times New Roman";
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    html, body {
        width: 210mm;
        height: 155mm;
        /*height: 297mm;*/
    }

    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}
